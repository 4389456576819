$text-color:       invert(#111);
$background-color: #0f0f0f;
$brand-color:      #2a7ae2;

$grey-color:       invert(#828282);
$grey-color-light: invert(lighten($grey-color, 40%));
$grey-color-dark:  invert(darken($grey-color, 25%));

@import "minima";

.highlight {
  background: invert(#fff);

  .highlighter-rouge & {
    background: invert(#eef);
  }
  .nt    { color: #6060d7 } // Name.Tag
}

pre, code {
  background: invert(#eef);
  border: 1px solid white;
}
